import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import './style.css'
import { Link, useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import { toast } from 'react-toastify';
import axios from "axios";
import logo from "../../image/test-tcf-logo-e1661831845386.webp"
import { setToken } from '../../observable/tokenObservable';
import Authentification from "../../components/Authentification/Authentification";

const Login = () => {

    const navigate = useNavigate()
    //const serverUrl = `http://13.48.177.86:8080/api/v1`;
    const serverUrl = "https://backend.tcf-canada-training.com/api/v1";
    const [option, setOption] = useState('')
    const [show, setShow] = useState(false)
    // const id1 = sessionStorage.getItem('idComprehensionEcrite')
    // const id2 = sessionStorage.getItem('idComprehensionOral')
    // const user = sessionStorage.getItem('userId')
    // const token = sessionStorage.getItem('token')

    const handleShow = ({option}) => {
        setShow(true)
        setOption(option)
    }
    const handleClose = () => {
        setShow(false)
        setOption('')
    }

    // useEffect(() => {
    //     if(token && user && id1 && id2) navigate('/')
    // }, [token, user, id1, id2, navigate])

    
    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Nom d\'utilisateur ou email requis'),
        password: Yup.string().required('Mot de passe requis'),
        rememberMe: Yup.boolean(),
    });

    
    const handleSubmit = async (values, { setSubmitting }) => {

        // const device = `${deviceInfo.engineName} ${deviceInfo.osName} ${deviceInfo.browserName}`
        let device
        if(localStorage.device) device = localStorage.device
        else{
            device = v4()
            localStorage.setItem('device', device)
        }
        console.log(device)
       
        toast.dismiss()
        toast.loading('Connexion en cours...')
        try{
            const response = await axios.post(`${serverUrl}/auth/login`,
                {
                    email: values.email,
                    password: values.password,
                    appareil: device
                },
                { headers: { 'Content-Type': 'application/json' },
                  withCredentials: true
                }
            );
            // sessionStorage.setItem("userId", response.data.id);
            // sessionStorage.setItem("token",response.data.token);

            await setToken(response.data.token, response.data.id)
            toast.dismiss()
            toast.success('Login successful')
            navigate('/')
        }catch(error){
            toast.dismiss()
            toast.dismiss()
            if (error.response) {
                toast.error(`Une erreur est survenenue pendant l'execution de l'operation`);
            } else if (error.request) {
                toast.error('Une erreur est surevenue pendant l\'execution de l\'operation');
            } else {
                toast.error(`Error: ${error.message}`);
                console.log(error.message)
            }
        }
        setSubmitting(false); 
    };

    return (
        <main className="login position-relative" style={{height: '90vh'}}>
            <Authentification 
                show={show}
                option={option}
                handleClose={handleClose} 
                handleShow={handleShow}
            />
            <div className="d-flex justify-content-center align-items-center gradient4 position-absolute start-0 top-0 w-100 h-100 ">
                <div className="container p-5 d-flex flex-column gap-md-5 gap-3 my-2">
                    <div className="logo mx-auto">
                        <img src={logo} alt="tcf logo" width={110} />
                    </div>
                    <Formik
                        initialValues={{ email: '', password: '' }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting }) => (
                        <Form className="d-flex flex-column gap-md-5 gap-3">
                            <div>
                                <Field
                                    className="w-100 rounded-pill py-3 px-3 fs-4 bg-white"
                                    type="text"
                                    name="email"
                                    placeholder="Email"
                                />
                                <ErrorMessage name="email" component="div" className="red"/>
                            </div>
                            <div>
                                <Field
                                    className="w-100 rounded-pill py-3 px-3 fs-4 "
                                    type="password"
                                    name="password"
                                    placeholder="Mot de passe"
                                />
                                <ErrorMessage name="password" component="div" className="red"/>
                            </div>
                            <div className="ms-2">
                            <label>
                              <Field type="checkbox" name="rememberMe" />
                              Se souvenir de moi
                            </label>
                          </div>
                            <button type="submit" disabled={isSubmitting} className="w-100 rounded-pill py-3 px-3 fs-4">
                                Se connecter
                            </button>
                        </Form>
                        )}
                    </Formik>
                    <div className="d-flex justify-content-between fs-4 gap-md-5">
                            <Link to='/mon-compte/lost-password'>Mot de passe oublie?</Link>
                            <span className="text-primary cursor-pointer" onClick={() => handleShow({option: 'incrire'})}>S'incrire</span>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Login
